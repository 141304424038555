/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

app-main {
  width: 100% !important;
  height: 100% !important;
  background: linear-gradient(0deg, #f1f3f5, #f1f3f5), rgba(101, 163, 13, 0.1) !important;
  --background: linear-gradient(0deg, #f1f3f5, #f1f3f5), rgba(101, 163, 13, 0.1) !important;
  // flex-direction: row !important;
  // justify-content: center !important;
  // align-items: center !important;
}

ion-split-pane {
  margin: auto !important;
  max-width: 1424px !important;
  --side-min-width: 70px !important;
  --side-max-width: 255px !important;
}

.d-flex {
  display: flex !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-500 {
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mb-025 {
  margin-bottom: 0.25rem !important;
}

.m-025 {
  margin: 0.25rem !important;
}

.mt-05 {
  margin-top: 0.5rem !important;
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

.ms-05 {
  margin-left: 0.5rem !important;
}

.me-05 {
  margin-right: 0.5rem !important;
}

.my-05 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mx-05 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.m-05 {
  margin: 0.5rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.m-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.p-025 {
  padding: 0.25rem !important;
}

.pt-025 {
  padding-top: 0.25rem !important;
}

.ps-05 {
  padding-left: 0.5rem !important;
}

.p-05 {
  padding: 0.5rem !important;
}

.pt-05 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}


.p-2 {
  padding: 2rem !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

[role="button"] {
  cursor: pointer !important;
}

ion-button {
  text-transform: none !important;
  --box-shadow: none !important;
}

.shadow-none {
  box-shadow: none !important;
  --box-shadow: none !important;
}

.shadow {
  box-shadow: 0px 10px 13px rgba(17, 38, 146, 0.05) !important;
}

.w-25 {
  width: 25% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-80 {
  width: 80% !important;
}

.w-100 {
  width: 100% !important;
}

.mw-500px {
  max-width: 500px !important;
}

.h-1px {
  height: 1px !important;
}

.h-100 {
  height: 100% !important;
}

.point {
  width: 1rem !important;
  height: 1rem !important;
  display: inline-block !important;
  vertical-align: text-top !important;
}

ion-item::part(native) {
  padding-left: 0px !important;
}

.item-inner {
  padding-right: 0px !important;
}

.active::part(native) {
  border: 1px solid var(--ion-color-primary) !important;
}

.inactive,
.inactive::part(native) {
  border: none !important;
  background-color: #e8e8e8 !important;
}

.bg-primary {
  background: var(--ion-color-primary) !important;
  --background: var(--ion-color-primary) !important;
}

.bg-white {
  background: white !important;
  --background: white !important;
}

.bg-yellow {
  background: #fbad42 !important;
  --background: #fbad42 !important;
}

.bg-grey {
  background: linear-gradient(0deg, #f1f3f5, #f1f3f5), rgba(101, 163, 13, 0.1) !important;
  --background: linear-gradient(0deg, #f1f3f5, #f1f3f5), rgba(101, 163, 13, 0.1) !important;
}

.bg-grey-10 {
  background: #e9ecef !important;
  --background: #e9ecef !important;
}

.bg-grey-20 {
  background: #adb5bd !important;
  --background: #adb5bd !important;
}

.bg-login {
  background: linear-gradient(64.5deg, #03162c 14.7%, #003eaf 88.7%) !important;
  --background: linear-gradient(
      64.5deg,
      #03162c 14.7%,
      #003eaf 88.7%
  ) !important;
}

.radius-8 {
  border-radius: 8px !important;
  --border-radius: 8px !important;
}

.radius-10 {
  border-radius: 10px !important;
  --border-radius: 10px !important;
}

.radius-circle {
  border-radius: 50% !important;
  --border-radius: 50% !important;
}

.img-24 {
  width: 24px !important;
  height: 24px !important;
}

.img-32 {
  width: 32px !important;
  height: 32px !important;
}

.box-53 {
  width: 53px !important;
  height: 53px !important;
}

.grade {
  top: 1rem !important;
  right: 0rem !important;
  width: 95px !important;
  height: 100px !important;
}

.lower-case {
  text-transform: capitalize !important;
}

.text-yellow {
  color: #fbad42 !important;
  --color: #fbad42 !important;
}

.text-red {
  color: red !important;
  --color: red !important;
}

.text-white {
  color: white !important;
  --color: white !important;
}

.text-grey-20 {
  color: #adb5bd !important;
  --color: #adb5bd !important;
}

.text-grey-50 {
  color: #727887 !important;
  --color: #727887 !important;
}

.text-grey-90 {
  color: #363939 !important;
  --color: #363939 !important;
}

.text-black-60 {
  color: #686a6a !important;
  --color: #686a6a !important;
}

.text-black-80 {
  color: #363939 !important;
  --color: #363939 !important;
}

.input {
  border: 1px solid #adb5bd;
  --border: 1px solid #adb5bd;
}

.input-clear-icon {
  opacity: 0.5 !important;
}

.md .date-icon {
  right: 1.5rem !important;
  top: 2.35rem !important;
}

.ios .date-icon {
  right: 1rem !important;
  top: 3.15rem !important;
}

.close-icon {
  right: -1.5rem !important;
  top: -1.5rem !important;
}

ion-modal:not(.modal-sheet):not(.fullscreen) {
  &::part(content) {
    height: 100% !important;
    margin: auto !important;
    max-width: 100% !important;
    --box-shadow: 0px !important;
    background-color: transparent !important;
  }

  ion-row {
    overflow-y: auto !important;
  }
}

// custom calendar
.monthview-container {
  height: unset !important;
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.monthview-datetable {
  background: white !important;
  --background: white !important;
  border-radius: 6px 6px 0px 0px !important;

  th {
    color: white !important;
    background: #383a3f !important;
  }

  th:first-child {
    border-radius: 6px 0px 0px 0px !important;
  }

  th:last-child {
    border-radius: 0px 6px 0px 0px !important;
  }

  td {
    padding: 0px !important;
    height: calc((100vw - 2rem) / 14) !important;

    .date-container {
      padding-right: 1rem !important;
    }

    .date-container > div {
      width: 100% !important;
      width: 2.25rem !important;
      height: 2.25rem !important;
      display: flex !important;
      border-radius: 50% !important;
      align-items: center !important;
      justify-content: center !important;

      div {
        text-align: center;
        line-height: 24px;
        height: 0;
        opacity: 0;
        z-index: -1;
        display: block;
        overflow: hidden;
        transform: rotate(315deg);
        transition: transform 0.5s, opacity 0.5s;
      }

      div.active {
        z-index: 0;
        opacity: 1;
        height: auto;
        transform: rotate(360deg);
      }
    }
  }
}

// remove default bg
.monthview-current,
.monthview-selected,
.calendar-event-inner,
.monthview-primary-with-event,
.monthview-secondary-with-event {
  background: transparent !important;
}

.monthview-current .date-container,
.today-schedule {
  color: black;
  background-color: #e6eaee;

  .class-schedule,
  .exam-schedule {
    color: white;
  }
}

.monthview-selected > .date-selected,
.monthview-selected .date-container {
  color: black;
  background-color: #dcecf9;

  .class-schedule,
  .exam-schedule {
    color: white;
  }
}

.monthview-selected .date-container-event > .class-schedule {
  background-color: rgba(40, 112, 220, 0.3) !important;
}

.monthview-selected .date-container-event > .exam-schedule {
  background-color: rgba(242, 24, 63, 0.3) !important;
}

.class-schedule {
  background-color: #2870dc !important;

  .class-schedule {
    top: -0.75rem !important;
    left: -0.5rem !important;
  }
}

.class-attendance {
  background-color: #34C759FF !important;

  .class-attendance {
    top: -0.75rem !important;
    left: -0.5rem !important;
  }
}

.exam-schedule {
  background-color: var(--ion-color-danger) !important;

  .exam-schedule {
    top: -0.75rem !important;
    right: -0.5rem !important;
  }
}

.class-schedule.exam-schedule.class-attendance {
  .class-schedule {
    top: -0.75rem !important;
    left: -0.5rem !important;
    background-color: #2870dc !important;
  }

  .exam-schedule {
    top: -0.75rem !important;
    right: -0.5rem !important;
    background-color: var(--ion-color-danger) !important;
  }

  .class-attendance {
    top: -0.75rem !important;
    right: -0.5rem !important;
    background-color: var(--ion-color-success) !important;
  }
}

.monthview-primary-with-event.monthview-selected > div.exam-schedule,
.monthview-primary-with-event.monthview-selected > div.class-schedule,
.monthview-primary-with-event.monthview-selected
> div.class-schedule.exam-schedule {
  background: var(--ion-color-warning) !important;
}

.monthview-primary-with-event > div.class-schedule.exam-schedule {
  background: linear-gradient(#2870dc, #2870dc),
  linear-gradient(var(--ion-color-danger), var(--ion-color-danger));
  background-size: 50% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.text-muted {
  .class-schedule,
  .exam-schedule {
    color: white;
  }
}

// xs
@media (max-width: 576px) {
  .circle-rectangle {
    border-radius: 50% !important;
    --border-radius: 50% !important;
  }

  .monthview-container {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .monthview-current
  .date-container
  > div:not(.class-schedule):not(.exam-schedule) {
    background-color: #e6eaee !important;
  }

  .monthview-selected
  .date-container
  > div:not(.class-schedule):not(.exam-schedule) {
    background-color: #dcecf9 !important;
  }

  .monthview-datetable {
    th,
    td {
      border: none !important;
    }

    td {
      height: calc((100vw - 2rem) / 7) !important;
      padding: 8px 0px !important;
      text-align: center !important;

      .date-container {
        display: block !important;
        padding-right: 0px !important;
        background-color: transparent !important;
      }

      .date-container > div {
        margin: auto !important;
      }
    }
  }

  .w-60 {
    width: 100% !important;
  }

  .d-xs-none {
    display: none !important;
  }

  .mt-sm-2 {
    margin-top: 1rem !important;
  }

  .mx-sm-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .my-sm-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .pt-sm-2 {
    padding-top: 1rem !important;
  }

  .py-sm-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-sm-25 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .mt-sm-2 {
    margin-top: 2rem !important;
  }

  .mx-sm-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .my-sm-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .mt-sm-3 {
    margin-top: 3rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 3rem !important;
  }

  .ms-sm-3 {
    margin-left: 3rem !important;
  }

  .mx-sm-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .pt-sm-2 {
    padding-top: 2rem !important;
  }

  .py-sm-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .px-sm-05 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-25 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .ps-sm-max {
    padding-left: 2rem !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .py-sm-25 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .ps-sm-max {
    padding-left: 4rem !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .py-sm-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .ps-sm-max {
    padding-left: 6rem !important;
  }
}

ion-radio {
  --border-radius: 4px;
  --inner-border-radius: 4px;

  --color: #ddd;
  --color-checked: var(--ion-color-primary);
}

ion-radio.ios {
  width: 20px;
  height: 20px;

  border: 2px solid #ddd;
  border-radius: 4px;
}

.radio-checked.ios {
  border-color: var(--ion-color-primary);
}


.tab {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 0.5rem;
  list-style: none;
  width: 100%;

  .tab-item {
    button {
      padding: 0.4rem 0.8rem;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      color: var(--ion-color-grey-primary);
      background: white;
      border-radius: 5px;
    }

    .active {
      color: white;
      background: var(--ion-color-primary);
    }
  }

  @media screen and (max-width: 500px) {
    padding: 0.1rem 0.8rem;
    justify-content: space-between;
    .tab-item {
      button {
        font-size: 11px;
      }
    }
  }
}
